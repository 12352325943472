import { FetcherValue } from "@nanostores/query";
import { Order } from "@stores/order";

export type ActivityState = 'LOADING' | 'AVAILABLE' | 'UNAVAILABLE' | 'IN_CART'

export function getActivityState(
  order : Readonly<FetcherValue<Order, any>>,
  { activityId, groupId } : { activityId: number, groupId: number }
) {
  if (order.loading) return 'LOADING'
  if (order.error) return 'UNAVAILABLE'
  if (order.data?.items.find(x => x.id === activityId)) return 'IN_CART'
  if (order.data?.items.filter(x => x.group.id === groupId).length >= 2) return 'UNAVAILABLE'

  return 'AVAILABLE'
}
