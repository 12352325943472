
type ComponentSelector = { component : string }
type ChildSelectors = { [ key : string ] : string | [string] }
type Selectors = ComponentSelector & ChildSelectors

export default function Hydrate<T extends HTMLElement, CT>(
  selectors : Selectors,
  cb : (args : { component : T } & CT) => void)
{
  document.addEventListener('DOMContentLoaded', () => {
    const {component: componentSelector, ...childSelectors} = selectors

    document.querySelectorAll<T>(componentSelector)
      .forEach(component => {

        const children = Object.keys(childSelectors).reduce((acc, key) => {
          const selector = childSelectors[key]
          acc[key] = (Array.isArray(selector))
            ? Array.from(component.querySelectorAll(selector[0]))
            : component.querySelector(selector)

          return acc
        }, {} as CT)

        cb({ component, ...children })
    })
  })

}
