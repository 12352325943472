import '@utilities/BodyStyleContext'

import './views/components/banner/banner'
import './views/components/slider/slider'
import './views/components/activity-grid/activity-grid'
import './views/components/activity-card/activity-card'
import './views/components/cart-button/cart-button'
import './views/components/order-button/order-button'
import './views/components/order-form/order-form'
import './views/components/parallax-shape/parallax-shape'


