import UIComponent from '@utilities/UIComponent'
import { createElement } from 'react'

type Component = HTMLFormElement

type ChildComponents = {
  activityContainers: HTMLDivElement[],
  templateGroupInput: HTMLTemplateElement
}

UIComponent<Component, ChildComponents>({
  component: '.js-OrderForm',
  activityContainers: ['.js-OrderForm-activity'],
  templateGroupInput: '.js-templateEmptyGroup'
}, ({ component, activityContainers, templateGroupInput }) => {

  activityContainers.forEach(activityContainer => {
    const groupContainer = activityContainer.querySelector<HTMLDivElement>('.js-OrderForm-groupContainer')
    const button = activityContainer.querySelector<HTMLButtonElement>('.js-OrderForm-addGroupButton')

    const activityId = button.dataset.activityId
    const groupName = button.dataset.groupName

    var idTracker = 0

    button.addEventListener('click', () => {
      idTracker++

      const clone = templateGroupInput.content.cloneNode(true) as DocumentFragment
      const removeButton = clone.querySelector<HTMLButtonElement>('.js-removeGroupButton')
      const wrapper = document.createElement('div')

      wrapper.appendChild(clone)

      wrapper.querySelectorAll<HTMLInputElement>('input')
        .forEach(x => {
          x.name = `activities[${activityId}][${idTracker}][${x.name}]`
        })

      wrapper.querySelector<HTMLLegendElement>('.js-OrderForm-legend')
        .textContent = groupName


      removeButton.addEventListener('click', () => {
        groupContainer.removeChild(wrapper)
      })

      groupContainer.append(wrapper)
    })
  })

})

// import RCTComponent from "@utilities/RCTComponent";

// import { OrderForm } from "./OrderForm";

// RCTComponent('.rct-order-form', OrderForm)
