import { $orderFetcher } from '@stores/order'
import { ActivityState, getActivityState } from '@utilities/OrderUtilities'
import UIComponent from '@utilities/UIComponent'

type Component = HTMLDivElement
type ChildComponents = {
  overlay: HTMLDivElement
}

UIComponent<Component, ChildComponents>({
  component: '.js-ActivityCard',
  overlay: '.js-ActivityCard-groupFullOverlay'
}, ({ component, overlay }) => {
  const activityId = parseInt(component.dataset.activityId)
  const groupId = parseInt(component.dataset.groupId)

  $orderFetcher.subscribe(order => handleState(getActivityState(order, { activityId, groupId })))

  function handleState(state : ActivityState) {
    switch(state) {
      case 'UNAVAILABLE':
        overlay.classList.toggle('ActivityCard-groupFullOverlay--active', true)
        break
      default:
        overlay.classList.toggle('ActivityCard-groupFullOverlay--active', false)
        break;
    }
  }
})
