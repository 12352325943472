import { Throttle } from "@utilities/Throttle"

(function() {
  document.addEventListener('DOMContentLoaded', () => {
    const styleChangers = Array.from(document.querySelectorAll<HTMLElement>('[data-scroll-style-context]'))

    window.addEventListener('scroll', Throttle(setStyleContext, 500))

    setStyleContext()

    function setStyleContext() {
      const viewportCenter = window.window.innerHeight / 2;

      const styleChanger = styleChangers
        .filter(x => x.getBoundingClientRect().top <= viewportCenter)
        .map(x => ({
          distance: Math.abs(x.getBoundingClientRect().top - viewportCenter),
          component: x
        }))
        .sort((a, b) => {
          if (a.distance < b.distance) return -1
          if (a.distance > b.distance) return 1
          return 0
        }).shift()

        if (styleChanger)
          document.body.dataset.styleContext = styleChanger.component.dataset.scrollStyleContext
    }
  })
})()
