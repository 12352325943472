import UIComponent from '@utilities/UIComponent'

type Component = HTMLDivElement
type Children = {
  filterButtons: HTMLButtonElement[]
  items: HTMLLIElement[]
}

UIComponent<Component, Children>({
  component: '.js-ActivityGrid',
  filterButtons: ['.js-ActivityGrid-filterButton'],
  items: ['.js-ActivityGrid-item']
}, ({ filterButtons, items }) => {

  filterButtons.forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault()
      const groupId = button.dataset.groupId

      items.forEach(item => {
        const itemGroupId = item.dataset.groupId
        item.classList.toggle('ActivityGrid-item--hidden', (groupId !== '*' && groupId !== itemGroupId))
      })

      filterButtons.forEach(button => {
        const buttonGroupId = button.dataset.groupId

        button.classList.toggle('ActivityGrid-filterButton--active', (groupId === buttonGroupId))
      })
    })
  })


})
