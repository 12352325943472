import UIComponent from '@utilities/UIComponent'
import KeenSlider from 'keen-slider'

type Component = HTMLDivElement
type Children = {
  slider: HTMLUListElement
  slides: HTMLLIElement[]
}

UIComponent<Component, Children>({
  component: '.js-Slider',
  slider: '.js-Slider-slider',
  slides: ['.js-Slider-slide']
}, ({ slider, slides }) => {
  const sliderInstance = new KeenSlider(
    slider,
    {
      selector: () => slides,
      slides: {
        spacing: 30,
        perView: 1.25
      },

      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            spacing: 30,
            perView: 1.5
          }
        },
        '(min-width: 1280px)': {
          slides: {
            spacing: 30,
            perView: 2.25
          }
        }
      }
      // created: (backButton || nextButton || progressBarInner) ? updateControls : undefined,
      // slideChanged: (backButton || nextButton || progressBarInner) ? updateControls : undefined,
      // updated: (backButton || nextButton || progressBarInner) ? updateControls : undefined,
    }
  )
})
