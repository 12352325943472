export function Throttle(fn : Function, delay : number = 300) {
  const state = {
    wait: false
  }

  return () => {
    if (state.wait) return

    fn();
    setInterval(() => state.wait = false, delay)
  }
}
