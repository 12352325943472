import { nanoquery } from '@nanostores/query';
import { atom } from 'nanostores';

export type OrderItemGroup = {
  id : number
  name : string
  slug : string
  description : string
}

export type OrderItem = {
  id : number
  post_type : 'cm-activity'
  title : string
  url : string
  image: {
    src : string
    alt : string
    id : number
  }
  group : OrderItemGroup
}

export type Order = {
  items: OrderItem[]
}

const endpoint = '/wp-json/cm-orders/v1/order'

export const [createFetcherStore, createMutatorStore] = nanoquery({
  fetcher: (...keys: (string | number)[]) => fetch(keys.join(''), { cache: 'no-cache' }).then((r) => r.json()),
  dedupeTime: 0,
  cacheLifetime: 0,
  revalidateOnReconnect: true,
  revalidateOnFocus: true
});

export const $orderFetcher = createFetcherStore<Order>(endpoint, {
  dedupeTime: 0,
  cacheLifetime: 0,
  revalidateOnReconnect: true,
  revalidateOnFocus: true
});

type AddParams = { activityId : number }

export const $addToOrder = createMutatorStore<AddParams>(
  async ({ data: { activityId }, revalidate, getCacheUpdater }) => {
    revalidate(endpoint)

    return fetch(`/wp-json/cm-orders/v1/order`, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        activityId
      })
    })
  },
  {
    throttleCalls: false,
  }
);

type DeleteParams = { activityId : number }

export const $deleteFromOrder = createMutatorStore<DeleteParams>(
  async ({ data: { activityId }, revalidate, getCacheUpdater }) => {
    revalidate(endpoint)

    return fetch(endpoint, {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        activityId
      })
    })
  },
  {
    throttleCalls: false,
  }
);
