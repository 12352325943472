export function camelCaseKeys(obj: any): any {
  if (Array.isArray(obj)) return obj.map(camelCaseKeys)

  if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};
    Object.keys(obj).forEach(key => {
      const camelCaseKey = key.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase())
      newObj[camelCaseKey] = camelCaseKeys(obj[key])
    })

    return newObj;
  }

  return obj;
}
