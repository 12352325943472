import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { camelCaseKeys } from '@utilities/FormatProps'

export default function RCTComponent(selector : string, App : (props : any) => ReactNode) {
  document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll<HTMLElement>(selector)

    elements.forEach((e) => {
      const jsonprops = e.dataset.props
      const props = (jsonprops) ? camelCaseKeys(JSON.parse(jsonprops)) : {}
      const root = createRoot(e)

      root.render(<App {...props} />)
    })
  })
}
