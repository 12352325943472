import UIComponent from '@utilities/UIComponent'
import { animate, scroll } from "motion"

type Component = HTMLDivElement
type ChildComponents = {
  shape: SVGElement
}

UIComponent<Component, ChildComponents>({
  component: '.js-ParallaxShape',
  shape: '.js-ParallaxShape-shape'
}, ({ shape }) => {
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches)
    return

  scroll(animate(shape, { y: [(-64 - (shape.getBoundingClientRect().height / 2)), (64 - (shape.getBoundingClientRect().height / 2))] }), { target: shape })
})
