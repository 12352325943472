import UIComponent from '@utilities/UIComponent'

UIComponent<HTMLDivElement, {illustrations : SVGElement[]}>(
  {
    component: '.js-Banner',
    illustrations: ['.js-Banner-illustration']
  }, ({ component, illustrations }) => {

    const state = {
      currentindex: 0,
      interval: null
    }

    const observer = new IntersectionObserver(entries => {
      if (entries.find(x => x.isIntersecting)) {
        state.interval = setInterval(() => {
          illustrations
            .filter(x =>  ! x.classList.contains('Banner-illustration--hidden'))
            .forEach(x => x.classList.add('Banner-illustration--hidden'))

          state.currentindex = (state.currentindex < (illustrations.length - 1))
            ? state.currentindex + 1
            : 0
          illustrations[state.currentindex].classList.remove('Banner-illustration--hidden')
        }, 7500)

        return
      }

      clearInterval(state.interval)
      state.interval = null
    });

    observer.observe(component)

  }
)
