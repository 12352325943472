import React from 'react'
import { useStore } from '@nanostores/react'
import { $deleteFromOrder, $orderFetcher, OrderItem } from '@stores/order';
import { autoUpdate, FloatingPortal, offset, size, useFloating } from '@floating-ui/react';

import IconXSolid from "tsx:./x-solid.svg";
import IconThrash from "tsx:./trash-solid.svg";
import IconChevronRight from "tsx:./chevron-right-solid.svg";
import IconCart from "tsx:./cart-shopping-solid.svg";

import styles from './OrderButton.module.css'


export function OrderButton() {

  const { data, loading } = useStore($orderFetcher);
  const [open, setOpen] = React.useState<boolean>(false)
  const {refs, floatingStyles} = useFloating({
    placement: 'top-end',
    middleware: [
      offset(19),
      size({
        apply({availableHeight, availableWidth, elements}) {
          elements.floating.style.maxHeight = `${availableHeight - 19}px`
          elements.floating.style.maxWidth = `${availableWidth - 10}px`
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const items = data?.items ?? []

  return (
    <div className={styles.component}>
      <button ref={refs.setReference} className={styles.button} onClick={() => setOpen(!open)}>
        <IconCart />
        <span>Mijn Bestelling ({data?.items.length})</span>
      </button>
      { open && (
        <FloatingPortal>
          <div className={styles.listContainer} ref={refs.setFloating} style={floatingStyles} >
            <OrderList {...{ items }} onClose={() => setOpen(false)} />
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

function OrderList({ items, onClose } : { items : OrderItem[], onClose : () => void }) {
  return (
    <div className={styles.componentOrderList}>
      <div className={styles.head}>
        <span>Mijn Bestelling</span>
        <button className={styles.closeButton} onClick={() => onClose()}><IconXSolid /></button>
      </div>
      { items.length === 0 && (
        <p className={styles.emptyOrder}>Er zijn nog geen activiteiten aan uw bestelling toegevoegd.</p>
      )}
      <ul className={styles.list}>
        {items.map((item, index) =>
          <li key={index}>
            <Item {...item} />
          </li>
        )}
      </ul>
      {items.length > 0 && (
        <div className={styles.footer}>
          <a className={styles.orderButton} href="/bestellen">
            <span>Bestelling Afronden</span>
            <IconChevronRight />
          </a>
        </div>
      )}
    </div>
  )
}

function Item({ id, title, image, group } : OrderItem) {
  return (
    <div className={styles.componentItem}>
      <img className={styles.itemImage} src={image.src} alt={image.alt} />
      <div className={styles.itemContent}>
        <h3 className={styles.itemTitle}>{ title }</h3>
        <div className={styles.badgeContainer}>
          <Badge title={group.name} />
          <Badge title={group.description} />
        </div>
      </div>
      <div className={styles.actions}>
        <button className={styles.deleteButton} onClick={() => $deleteFromOrder.mutate({ activityId: id })}>
          <IconThrash />
        </button>
      </div>
    </div>
  )
}

function Badge({ title } : { title : string }) {
  return (
    <span className={styles.componentBadge} dangerouslySetInnerHTML={{ __html: title }}></span>
  )
}
