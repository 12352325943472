import UIComponent from '@utilities/UIComponent'
import { $orderFetcher, $addToOrder, $deleteFromOrder, Order } from '@stores/order'
import { FetcherValue } from '@nanostores/query'
import { ActivityState, getActivityState } from '@utilities/OrderUtilities'
type Component = HTMLDivElement

type ChildComponents = {
  button: HTMLButtonElement
  deleteTemplate: HTMLTemplateElement
  loadingTemplate: HTMLTemplateElement
  unavailableTemplate: HTMLTemplateElement
  addTemplate: HTMLTemplateElement
}


UIComponent<Component, ChildComponents>({
  component: '.js-CartButton',
  button: '.js-CartButton-button',
  deleteTemplate: '.js-CartButton-templateDelete',
  loadingTemplate: '.js-CartButton-templateLoading',
  unavailableTemplate: '.js-CartButton-templateUnavailable',
  addTemplate: '.js-CartButton-templateAdd'
}, ({ button, deleteTemplate, loadingTemplate, addTemplate, unavailableTemplate }) => {

  const activityId = parseInt(button.dataset.activityId)
  const groupId = parseInt(button.dataset.groupId)

  $orderFetcher.subscribe(order => handleState(getActivityState(order, { activityId, groupId })))

  function handleState(state : ActivityState) {
    switch(state) {
      case 'AVAILABLE':
        applyTemplate(addTemplate)
        button.removeEventListener('click', deleteFromOrder)
        button.addEventListener('click', addToOrder, { passive: false })
        button.disabled = false
        button.classList.toggle('CartButton-button--hidden', false)

        break
      case 'IN_CART':
        applyTemplate(deleteTemplate)
        button.removeEventListener('click', addToOrder)
        button.addEventListener('click', deleteFromOrder, { passive: false  })
        button.disabled = false
        button.classList.toggle('CartButton-button--hidden', false)

        break
      case 'LOADING':
        button.disabled = true
        break
      case 'UNAVAILABLE':
      default:
        applyTemplate(unavailableTemplate)
        button.disabled = true
        button.classList.toggle('CartButton-button--hidden', true)
        break;
    }
  }

  function addToOrder() {
    $addToOrder.mutate({ activityId })
  }

  function deleteFromOrder() {
    $deleteFromOrder.mutate({ activityId })
  }

  function applyTemplate(template : HTMLTemplateElement) {
    const temp = template.content.cloneNode(true)
    button.replaceChildren(temp)
  }
})
